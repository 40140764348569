<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
         
      <div class="newsSwiper">  
        <swiper :options="newsSwiper">
          <swiper-slide v-for="(item,key) in newsTopList" :key="key">
              <img @click="gotoDetail(item)" :src="`${$baseUrl}${item.thumb}`"/>
          </swiper-slide>
        </swiper> 
        <div class="swiper-pagination"></div>
        <div class="newsSwiperNext"><img src="@/assets/r.png" width="12" /></div>
        <div class="newsSwiperPrev"><img src="@/assets/l.png" width="12" /></div>
      </div>
      <div class="p20">
        <div><img src="@/assets/t8.png" width="239" /></div>

        <div class="newsListBg">
          <div class="newsListBlock mt20" v-for="(item,key) in newsList" :key="key" @click="gotoDetail(item)">
            <h3 class="ellipsis2">
               {{item.title}}
            </h3>
            <el-row :gutter="10">
              <el-col :span="10">
                <router-link to="/news/1">
                  <!-- <img src="@/assets/p14.jpg"> -->
                  <img :src="`${$baseUrl}${item.thumb}`"/>
                  <div class="list2Date">{{__commonJs['happenTimeFun'](item.inputtime * 1000,'ymd')}}</div>
                </router-link>
              </el-col>
              <el-col :span="14">
                <div class="boxText ellipsis6">
                  {{item.description}}
                </div>
              </el-col>
            </el-row>
          </div>  
        </div>


      </div>

      

      
      <!-- <div class="p20">
        <div class="paging">
          <span class="prev disable"><img src="@/assets/prev.png" width="6" /></span>
          <div class="digital">
            <span class="cut">1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
          </div>
          <span class="next"><img src="@/assets/next.png" width="6" /></span>
        </div>
      </div> -->

      <Footer />

      
    </div>
    
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      isIndex: false,
      newsTopList: [],
      newsList: [],
      newsSwiper: {
          slidesPerView: 1, 
          spaceBetween: 0,
          pagination:{
            el:'.newsSwiper .swiper-pagination',
            clickable:true,
          }
        // Some Swiper option/callback...
      },
      baseUrl : 'https://www.suzhousco.com'
    }
  },
  computed: {
      
  },
  mounted() {
    this.getNewsTop()
    this.getNewsList()
  },
  methods:{
    // 顶部焦点图
    getNewsTop() {
      this.$ajax
        .get("/cms/api/newslist", {
          dtype: "top",
        })
        .then((res) => {
          if (res.code == "200") {
            this.newsTopList = res.data.slice(0, 6);
            console.log(this.newsList);
          }
        });
    },
    // 获取新闻列表
    getNewsList() {
      this.$ajax.get("/cms/api/newslist", {}).then((res) => {
        if (res.code == "200") {
          this.newsList = res.data
          console.log(this.newsList);
        }
      });
    },

    gotoDetail(item) {
      this.$router.push({ name: "NewsView", params: { id: item.id } });
    },
  },
}
</script>

<style scoped>
.pageMain img{
  max-width: 100%;
}
</style>

